import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import Axios or your preferred HTTP client
import { storeItemActions, cartActions } from "../../../actions";
import {
  Typography,
  Link,
  Grid,
  Box,
  Paper,
  Button,
  Stack,
  Card,
  CardContent,
  IconButton,
  Chip,
  CardMedia,
  CardActions,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Divider,
  ListItem,
  List,
  Alert,
  AlertTitle,
} from "@mui/material";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import CartItem from "../../../components/customer/store/cart/CartItem/CartItem.component";
import CartTotals from "../../../components/customer/store/cart/CartTotals/CartTotals.component";
import { getCart, deleteEntireCart } from "../../../helpers";

const CustomerStoreCart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userState = useSelector((state) => state.authentication?.user);
  const storeItemsState = useSelector((state) => state.storeItems);
  const cartState = useSelector((state) => state.cartItems);

  const [cartItems, setCartItems] = useState([]);
  const [cartItemTotal, setCartItemTotal] = useState(null);
  const [cartDeliveryTotal, setCartDeliveryTotal] = useState(null);
  const [cartITotal, setCartTotal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/store">
      Store
    </Link>,
    <Typography key="3">Cart</Typography>,
  ];

  // Fetch cart items
  useEffect(() => {
    const getCartItems = () => {
      const items = getCart();
      setCartItems(items);
    };

    getCartItems();
  }, []);

  // Calculate totals
  useEffect(() => {
    const calculateTotals = () => {
      let cit = 0;
      let cdt = 0;

      cartItems.map((item) => {
        cit += item.itemTotal;
        cdt += item.deliveryCharge;

        return null;
      });

      setCartItemTotal(cit);
      setCartDeliveryTotal(cdt);
      setCartTotal(cit + cdt);
    };

    calculateTotals();
  }, [cartItems]);

  // Checkout handler
  const handleCheckout = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post("/customer/checkout", cartItems);

      if (response.status === 200) {
        // Navigate to success page on successful checkout

        deleteEntireCart();

        const invoice = response.data.data;

        navigate("/store/cart/success", { state: { invoice } });
      } else {
        throw new Error("Checkout failed. Please try again.");
      }
    } catch (error) {
      setError(
        error.response?.data?.message || "An error occurred during checkout."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Intro
        pageTitle={`Shopping Cart`}
        pageTitleShort="Store"
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />

      <Content>
        {cartItems.length > 0 ? (
          <Grid container>
            <Grid item xs={7} sx={{ pr: 5 }}>
              <Box>
                <Paper elevation={0}>
                  <Stack spacing={3}>
                    {cartItems?.map((item) => (
                      <CartItem key={item?.id} item={item} />
                    ))}
                  </Stack>
                </Paper>
              </Box>
            </Grid>

            <Grid item xs={5}>
              <Card>
                <CardContent sx={{ px: 3, py: 2 }}>
                  <Typography variant="h5" sx={{ mt: 0, mb: 1 }}>
                    Cart Totals
                  </Typography>

                  <Typography color="text.secondary" gutterBottom>
                    Confirm the items in the cart and click checkout to proceed
                    to the payment.
                  </Typography>

                  <CartTotals
                    items={cartItems}
                    cartItemTotal={cartItemTotal}
                    cartDeliveryTotal={cartDeliveryTotal}
                    cartITotal={cartITotal}
                  />

                  {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                      {error}
                    </Alert>
                  )}
                </CardContent>

                <CardActions sx={{ px: 3, pb: 3 }}>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    sx={{ mx: "auto" }}
                    onClick={handleCheckout}
                    disabled={isLoading}
                  >
                    {isLoading ? "Processing..." : "Proceed to Checkout"}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item sx={12}>
              <Alert severity="info">
                <AlertTitle>Your Cart is Empty</AlertTitle>
                Please add items to your cart before continuing
                <Button
                  variant="contained"
                  sx={{ display: "block", mt: "1.2rem" }}
                  color="primary"
                  onClick={() => {
                    navigate("/store");
                  }}
                >
                  Go to Store
                </Button>
              </Alert>
            </Grid>
          </Grid>
        )}
      </Content>
    </>
  );
};

export default CustomerStoreCart;
