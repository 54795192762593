import {
  FormControl,
  Stack,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Button,
  FormHelperText,
  Box,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Notification from "../../Common/Notification";
import styled from "@emotion/styled";
import PropTypes, { array } from "prop-types";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import AlertToast from "../common/Toast";
import { apiConstants } from "../../constants";
import { getUserToken } from "../../helpers";

const StyledHeaderRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.neutral.light,
  height: "50px",
  fontWeight: 500,
  textAlign: "center",
}));

const SalesOrderItems = ({ ...props }) => {
  const {
    sizes,
    item_details,
    displayData,
    setData,
    data,
    activeStep,
    editStatus,
    error,
    setError,
    setDisabledSubmitBtn,
    readOnly,
  } = props;

  const [edit, setEdit] = useState(editStatus);

  const [itemDetails, setItemDetails] = useState([]);

  const [itemDetailsWithQuantity, SetItemDetailsWithQuantity] = useState([]);

  const [toastState, setToastState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastMessage, setToastMessage] = useState("");
  const handleToastClose = () => setToastState({ open: false });

  const [uploadFile, setUploadFile] = useState("");

  const [checkedCustomizeFields, setCheckedCustomizeFields] = useState(false);

  useEffect(() => {
    setItemDetails(item_details);
    setEdit(false);
    setCheckedCustomizeFields(displayData?.flag_name_no);
    SetItemDetailsWithQuantity(displayData?.itemDetailsWithQuantity);
    setDisabledSubmitBtn(true);
    if (
      item_details.every((el) => el.size_id && el.name && el.number) &&
      displayData?.flag_name_no === 1
    ) {
      setDisabledSubmitBtn(false);
    }
    let sum = 0;
    console.log("itemDetailsWithQuantity");
    console.log(itemDetailsWithQuantity);

    if (!itemDetailsWithQuantity) {
      setDisabledSubmitBtn(false);
    }

    if (itemDetailsWithQuantity && itemDetailsWithQuantity.length !== 0) {
      sum = itemDetailsWithQuantity
        ?.map((o) => o.quantity)
        ?.reduce((a, c) => {
          return parseInt(a) + parseInt(c);
        });
    }
    if (
      itemDetailsWithQuantity &&
      itemDetailsWithQuantity?.every((el) => el.size_id && el.quantity) &&
      parseInt(sum) === displayData?.qty
    ) {
      setDisabledSubmitBtn(false);
    }
    setUploadFile(displayData?.item_details_file_path);
  }, [item_details, displayData, itemDetailsWithQuantity]);

  const onChangeFields = (e, index) => {
    const { id, value } = e.target;
    let newData = data;
    newData.items[activeStep].item_details[index][id] = value;
    setData(newData);
    let details = item_details;
    details[index][id] = value;
    setItemDetails(details);
    if (details.every((el) => el.size_id && el.name && el.number)) {
      setDisabledSubmitBtn(false);
    } else {
      setDisabledSubmitBtn(true);
    }
  };

  const onChangeQuantity = (e, index) => {
    const { id, value } = e.target;
    itemDetailsWithQuantity[index][id] = value;
    SetItemDetailsWithQuantity(itemDetailsWithQuantity);
    const sizeId = itemDetailsWithQuantity[index].size_id;
    let sum = itemDetailsWithQuantity
      .map((o) => o.quantity)
      .reduce((a, c) => {
        return parseInt(a) + parseInt(c);
      });
    if (parseInt(sum) === displayData?.qty && sizeId !== 0) {
      setError({ status: false, message: "" });
      setDisabledSubmitBtn(false);
    } else if (sum > displayData?.qty) {
      setError({
        status: true,
        message:
          "fields of size total of quantity cannot greater than quantity of item",
        index: index,
      });
      setDisabledSubmitBtn(true);
    } else {
      setError({ status: false, message: "" });
      setDisabledSubmitBtn(true);
    }
  };

  const handleChange = (e, index, sizeValue) => {
    let newData = data;
    if (sizeValue) {
      newData.items[activeStep].item_details[index].size_id = sizeValue?.id;
      newData.items[activeStep].item_details[index].size_name = sizeValue?.name;
      newData.items[activeStep].item_details[index].selectedSize.id =
        sizeValue?.id;
      newData.items[activeStep].item_details[index].selectedSize.name =
        sizeValue?.name;
    }
    setData(newData);
    if (
      newData.items[activeStep].item_details.every(
        (el) => el.size_id && el.name && el.number
      )
    ) {
      setDisabledSubmitBtn(false);
    } else {
      setDisabledSubmitBtn(true);
    }
  };

  const handleChangeWithQuantity = (e, index, sizeValue) => {
    let newData = data;
    console.log(itemDetailsWithQuantity[index]);
    if (sizeValue) {
      itemDetailsWithQuantity[index].size_id = sizeValue?.id;
      itemDetailsWithQuantity[index].size_name = sizeValue?.name;
      itemDetailsWithQuantity[index].selectedSize.id = sizeValue?.id;
      itemDetailsWithQuantity[index].selectedSize.name = sizeValue?.name;
      newData.items[activeStep].itemDetailsWithQuantity[index].size_id =
        sizeValue?.id;
      newData.items[activeStep].itemDetailsWithQuantity[index].size_name =
        sizeValue?.name;
      newData.items[activeStep].itemDetailsWithQuantity[index].selectedSize.id =
        sizeValue?.id;
      newData.items[activeStep].itemDetailsWithQuantity[
        index
      ].selectedSize.name = sizeValue?.name;
    }
    setData(newData);
    let sum = itemDetailsWithQuantity
      .map((o) => o.quantity)
      .reduce((a, c) => {
        return parseInt(a) + parseInt(c);
      });
    if (sizeValue?.id && parseInt(sum) === displayData?.qty) {
      setDisabledSubmitBtn(false);
    }
  };

  const sizesProps = {
    options: sizes,
    getOptionLabel: (option) => option?.name || "",
  };

  const handleCheckedChange = (e) => {
    let newData = data;
    setCheckedCustomizeFields(e.target.checked);
    newData.items[activeStep].flag_name_no = e.target.checked;
    setData(newData);
  };

  const addNewRow = () => {
    let newData = data;
    let sum = itemDetailsWithQuantity
      .map((o) => o.quantity)
      .reduce((a, c) => {
        return parseInt(a) + parseInt(c);
      });
    const items = {
      size_id: 0,
      quantity: "",
      selectedSize: { id: 0, name: "" },
    };
    if (!itemDetailsWithQuantity.every((el) => el.quantity && el.size_id)) {
      setToastMessage("fill every fields");
      setToastState({
        open: true,
        vertical: "top",
        horizontal: "right",
      });
    } else if (sum < displayData?.qty) {
      SetItemDetailsWithQuantity([...itemDetailsWithQuantity, items]);
      newData.items[activeStep].itemDetailsWithQuantity = [
        ...itemDetailsWithQuantity,
        items,
      ];
      setData(newData);
    } else if (sum >= displayData?.qty) {
      setToastMessage("Cannot add new item total of quantity not available");
      setToastState({
        open: true,
        vertical: "top",
        horizontal: "right",
      });
    }
  };

  const handleUploadFile = (e) => {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    const token = getUserToken();
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    };

    let newData = data;

    fetch(
      `${apiConstants.API_URL}/file/sales-order-item-details`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const res = text && JSON.parse(text);
        if (res?.data?.path) {
          newData.items[activeStep].item_details_file_path = res?.data?.path;
          setUploadFile(res?.data?.path);
          setData(newData);
        }
      });
    });
  };

  const removeImage = () => {
    let newData = data;
    newData.items[activeStep].item_details_file_path = "";
    setUploadFile("");
    setData(newData);
  };

  const deleteRows = (index) => {
    console.log("hi log");
    const rows = [...itemDetailsWithQuantity];
    rows.splice(index, 1);
    SetItemDetailsWithQuantity(rows);
    let newData = data;
    newData.items[activeStep].itemDetailsWithQuantity = [...rows];
    setData(newData);
    setDisabledSubmitBtn(true);
  };

  const truncate = (str) => {
    const name = str.split("/");
    return name[3];
  };

  return (
    <>
      {itemDetailsWithQuantity && (
        <>
          <Stack>
            <Typography style={{ fontSize: "20px", fontWeight: 700 }}>
              Sizes & Quantities
            </Typography>
            <Typography style={{ fontSize: "15px" }}>
              Please select the sizes and you wish to add to the Order
            </Typography>
          </Stack>
          <Notification
            title="Notes"
            body="Numbers and names will be printed exactly as per this table so please ensure spelling and case of letters are correct (e.g. Capitals)"
            extra="If the names and/or numbers are not to be printed onto garments and are packing purposes only, please specify under “Comments”"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedCustomizeFields}
                onChange={handleCheckedChange}
                name="checked"
              />
            }
            label="Customize Name and Number"
          />
          {!checkedCustomizeFields ? (
            <>
              <Table>
                <thead>
                  <StyledHeaderRow>
                    <TableCell>Garment Size</TableCell>
                    <TableCell>
                      {checkedCustomizeFields ? "Number" : "Quantity"}
                    </TableCell>
                    <TableCell>
                      {checkedCustomizeFields ? "Name" : "Action"}
                    </TableCell>
                  </StyledHeaderRow>
                </thead>
                <tbody>
                  {itemDetailsWithQuantity?.map((i, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell>
                          <FormControl
                            fullWidth
                            variant="standard"
                            sx={{ width: "100%", minWidth: "200px" }}
                          >
                            <Autocomplete
                              {...sizesProps}
                              id="controlled-demo"
                              autoComplete
                              includeInputInList
                              value={i?.selectedSize}
                              onChange={(e, value) => {
                                //setSizes(value);
                                handleChangeWithQuantity(e, index, value);
                              }}
                              disabled={!!readOnly}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Size"
                                  variant="standard"
                                  disabled={!!readOnly}
                                />
                              )}
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormControl>
                            {edit ? (
                              <TextField
                                size="small"
                                id="quantity"
                                placeholder="Quantity"
                                onChange={(e) => onChangeQuantity(e, index)}
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                sx={{ width: "100%" }}
                                disabled={!!readOnly}
                              />
                            ) : (
                              <TextField
                                size="small"
                                placeholder="Quantity"
                                value={i?.quantity}
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onClick={() => setEdit(!edit)}
                                sx={{ width: "100%" }}
                                disabled={!!readOnly}
                              />
                            )}
                          </FormControl>
                        </TableCell>

                        <TableCell>
                          {index !== 0 && (
                            <RemoveCircleOutline
                              onClick={() => deleteRows(index)}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </tbody>
              </Table>
              {!readOnly && (
                <>
                  <Stack direction="row" justifyContent="space-between">
                    <Button
                      variant="outlined"
                      sx={{ width: "200px" }}
                      onClick={addNewRow}
                    >
                      {" "}
                      <AddCircleOutline
                        sx={{
                          marginRight: "10px",
                          width: "20px",
                          height: "20px",
                        }}
                      />{" "}
                      Add New Row{" "}
                    </Button>
                    {error.status && (
                      <FormHelperText
                        id="component-error-text"
                        sx={{ color: "red" }}
                      >
                        {error.message}
                      </FormHelperText>
                    )}
                  </Stack>

                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    spacing={1}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Typography sx={{ fontStyle: "italic" }}>
                        Template File:
                      </Typography>
                      <Typography sx={{ fontStyle: "italic" }}>
                        Please download the template file and fill in the
                        details
                      </Typography>
                    </Box>
                    <Button
                      href="/downloads/Sales Order Numbers and Names Template.xlsx"
                      variant="outlined"
                    >
                      Download
                    </Button>
                  </Stack>

                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    spacing={1}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Typography sx={{ fontStyle: "italic" }}>
                        Attachments:
                      </Typography>
                      <Typography sx={{ fontStyle: "italic" }}>
                        Please attach Excel sheet of items with Numbers and
                        Names
                      </Typography>
                    </Box>
                    {uploadFile && (
                      <Stack
                        sx={{
                          padding: "0 10px",
                          borderRadius: 3,
                          backgroundColor: "rgba(239, 239, 239, 0.65)",
                        }}
                        direction={"row"}
                        justifyContent="space-between"
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography>{truncate(uploadFile)}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Button sx={{ minWidth: 0 }} onClick={removeImage}>
                            x
                          </Button>
                        </Box>
                      </Stack>
                    )}
                    {!uploadFile && (
                      <Box
                        sx={{
                          borderRadius: "8px",
                          backgroundColor: "rgba(239, 239, 239, 0.65)",
                          border: "1px dash #9E9E9E",
                          height: "59px",
                          width: "100%",
                          padding: "0 10px",
                        }}
                      >
                        <Stack
                          sx={{ marginTop: "12px" }}
                          direction={"row"}
                          justifyContent="space-between"
                        >
                          <Box>
                            <Typography>File Name</Typography>
                          </Box>

                          <Box>
                            <label htmlFor={"image"}>
                              <Button
                                variant="outlined"
                                style={{ width: "60px", pointerEvents: "none" }}
                              >
                                Upload
                              </Button>
                            </label>
                            <input
                              type="file"
                              id={`image`}
                              name={`image`}
                              accept="*"
                              onChange={(e) => {
                                handleUploadFile(e);
                              }}
                              style={{
                                position: "absolute",
                                pointerEvents: "none",
                                visibility: "hidden",
                              }}
                            />
                          </Box>
                        </Stack>
                      </Box>
                    )}
                  </Stack>
                </>
              )}
            </>
          ) : (
            <Table>
              <thead>
                <StyledHeaderRow>
                  <TableCell>Garment Size</TableCell>
                  <TableCell>Number</TableCell>
                  <TableCell>Name</TableCell>
                </StyledHeaderRow>
              </thead>
              <tbody>
                {itemDetails.map((i, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <FormControl
                        fullWidth
                        variant="standard"
                        sx={{ width: "100%", minWidth: "200px" }}
                      >
                        <Autocomplete
                          {...sizesProps}
                          id="controlled-demo"
                          autoComplete
                          includeInputInList
                          value={i?.selectedSize}
                          onChange={(e, value) => {
                            handleChange(e, index, value);
                          }}
                          disabled={!!readOnly}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Size"
                              variant="standard"
                              disabled={!!readOnly}
                            />
                          )}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl
                        fullWidth
                        variant="standard"
                        sx={{ width: "100%" }}
                      >
                        {edit ? (
                          <TextField
                            size="small"
                            id="number"
                            placeholder="Number"
                            onChange={(e) => onChangeFields(e, index)}
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={!!readOnly}
                          />
                        ) : (
                          <TextField
                            size="small"
                            placeholder="Number"
                            value={i?.number}
                            onClick={() => setEdit(!edit)}
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={!!readOnly}
                          />
                        )}
                      </FormControl>
                    </TableCell>

                    <TableCell>
                      {edit ? (
                        <TextField
                          size="small"
                          id="name"
                          placeholder="Name"
                          onChange={(e) => onChangeFields(e, index)}
                          sx={{ width: "100%" }}
                          disabled={!!readOnly}
                        />
                      ) : (
                        <TextField
                          size="small"
                          placeholder="Name"
                          value={i?.name}
                          onClick={() => setEdit(!edit)}
                          sx={{ width: "100%" }}
                          disabled={!!readOnly}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          )}
          <AlertToast
            toastMessage={toastMessage}
            toastState={toastState}
            handleClose={handleToastClose}
          />
        </>
      )}
    </>
  );
};

SalesOrderItems.defaultProps = {
  sizes: [],
  item_details: [],
};
SalesOrderItems.propsTypes = {
  sizes: PropTypes.array,
  item_details: array,
};

export default SalesOrderItems;
