import {
  Box,
  Button,
  Card,
  Divider,
  Modal,
  Stack,
  Table,
  Typography,
  styled,
} from "@mui/material";
import * as React from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { convertToPlaces } from "../../../helpers/helpers";
import { generatePDF } from "../../../helpers/pdf-gen";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import { ADD_REQUEST } from "../../../constants/Constants";
import AdditionalCustomerQuotation from "../../../Common/Modals/AddtionalCustomerQuotation";
import { authHeader, getCurrentUser } from "../../../helpers";
import { apiConstants } from "../../../constants";
import ProgressCircularWithBackdrop from "../../../Common/ProgressCircular/ProgressCircularWithBackdrop";
import SuccesModal from "../../../Common/Modals/SuccessModal";
import AlertToast from "../../common/Toast";
import { useNavigate, useParams } from "react-router-dom";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutral.light,
  height: "70px",
}));

const StyledItalic = styled("i")({
  fontSize: "12px",
});

const StyledLinked = styled(Typography)({
  textDecoration: "underline",
  textUnderlineOffset: "0.2em",
  cursor: "pointer",
});

const StyledHeader = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.neutral.light,
  textAlign: "left",
  height: "93px",
  paddingLeft: "10px",
  paddingTop: "10px",
}));

const StyledTableCell = styled(TableCell)({
  width: "200px",
  fontWeight: 700,
});

const StyledImageContainer = styled("div")({
  background: "rgba(239, 239, 239, 0.65)",
  border: "2px dashed #9E9E9E",
  borderRadius: "8px",
  width: "100%",
  height: "auto",
});

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.neutral.light,
  padding: "20px",
}));

const StyledTypography = styled(Typography)({
  fontWeight: "400",
  fontSize: "14px",
});

const useStyles = makeStyles({
  section: {
    padding: "30px 40px",
  },
  termsAndConditionsHeader: {
    fontSize: "20px !important",
    fontWeight: "500 !important",
    marginBottom: "30px !important",
  },
  termsAndConditionRow: {
    display: "flex",
    "& .MuiTypography-root:first-child": {
      textAlign: "right",
      width: "20px",
      marginRight: "9px",
    },
    "& .MuiTypography-root:last-child": {
      textAlign: "left",
      width: "100%",
    },
  },
});

export const QuotationContainer = ({
  organizationName,
  originalTotal,
  items,
  file,
  extraCharges,
  totalDiscount,
  totalPrice,
  totalDelivery,
  grandTotal,
  gst,
  acceptButtons,
  actionModels,
  addNoteContainer,
  quotationStatus,
  organization,
  responded,
  artWorkCharges,
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const downloadableElement = React.createRef();
  const downloadButton = React.createRef();
  const { roles } = getCurrentUser();
  // console.log(quotationStatus,"totallllllllllllll-del")
  const [agree, setAgree] = useState(false);
  const [create, setCreate] = useState(false);
  const [openAdditional, updateAdditional] = useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [openProgressCircular, setOpenProgressCircular] = React.useState(false);
  const handleToastClose = () => setToastState({ open: false });
  const [notes, setNotes] = useState("");
  const [addNote, setAddNote] = useState(false);
  const [toastState, setToastState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const navigate = useNavigate();

  const [success, setSuccess] = useState({
    open: false,
    title: "",
    body: "",
  });
  const [error, setError] = React.useState({
    value: false,
    name: "",
    message: "",
  });

  const hasDiscount = items.some(
    (item) => item.unit_discount && item.unit_discount > 0
  );

  console.log("roles", roles);

  const submitAcceptQuotation = (type, notes = "") => {
    setOpenProgressCircular(true);
    const requestOptions = {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify({ note: notes }),
    };

    return fetch(
      `${apiConstants.API_URL}/customer/quotations/${id}/${type}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          if (response.status === 401) {
            // logout()
          }
          const error = (data && data.error) || response.statusText;
          updateAdditional(false);
          setOpenProgressCircular(false);
          setToastMessage(error);
          setToastState({
            open: true,
            vertical: "top",
            horizontal: "right",
          });
        } else {
          // responded(true);
          if (type === "accept-quotation") {
            setSuccess({
              open: true,
              title: "Quotation is Accepted",
              body: `Quotation #${data.data.id} is accepted and we are ready to get started. A S-Trend team member will contact you and guide you through the process.`,
            });
          } else {
            setSuccess({
              open: true,
              title: "Quotation has been Rejected",
              body: "Quotation Has been Rejected Successfully",
            });
            updateAdditional(false);
          }
          setAgree(false);
          setOpenProgressCircular(false);
        }
      });
    });
  };

  const acceptAction = () => {
    request("accept", notes);
  };

  const request = (type, notes = "") => {
    if (type === "reject") {
      if (notes !== "") {
        submitAcceptQuotation("reject-quotation", notes);
      } else {
        setError({ value: true, name: "", message: "Reason is required" });
      }
    } else {
      submitAcceptQuotation("accept-quotation", notes);
    }
  };

  const checkBoxHandle = (e) => {
    const { id, checked } = e.target;

    setAgree(() => checked);
  };

  const addMessage = (type, message) => {
    setNotes(() => message);
    setAddNote(false);
  };

  const downloadPdf = React.useCallback(() => {
    const downloadEle = downloadableElement.current;
    const downButton = downloadButton.current;
    const parentElement = downButton.parentElement;
    parentElement.removeChild(downButton);
    generatePDF(downloadEle, "quotation.pdf");
    parentElement.appendChild(downButton);
  }, [downloadableElement, downloadButton]);

  const downloadUsingAnchorElement = React.useCallback(
    async (index) => {
      const anchor = document.createElement("a");
      anchor.href = file[index].file_url;
      anchor.download = "*";
      document.body.appendChild(anchor);
      anchor.click();
    },
    [file]
  );

  return (
    <div ref={downloadableElement}>
      <Box sx={{ padding: "40px 40px 20px" }}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Box sx={{ display: "flex" }}>
            <Typography component="span">Dear </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                paddingLeft: "3px",
                textTransform: "capitalize",
              }}
            >
              {organizationName},
            </Typography>
          </Box>
          <Button
            variant="contained"
            ref={downloadButton}
            onClick={downloadPdf}
          >
            Download Quotation
          </Button>
        </Stack>
        <Box sx={{ padding: "0 0 10px" }}>
          <Typography sx={{ padding: "10px 0" }}>
            Thank you for your interest in our products - the team is incredibly
            excited to get started on this opportunity. We have attached a quote
            for your consideration. As our prices are dictated by the quantity
            ordered, please let us know if you wish to alter the quantities we
            have provided in the quote. If you are happy to move forward, please
            accept this Quote and we will get to work! Alternatively, please do
            not hesitate to get in touch if you have any questions or concerns.
          </Typography>
          <Typography>Best wishes,</Typography>
          <Typography>Team S-Trend</Typography>
        </Box>
      </Box>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{ padding: "40px 25px 40px 40px;", backgroundColor: "#F4F4F4" }}
      >
        <div>
          <Card style={{ minWidth: "500px" }}>
            <Stack direction={"column"} justifyContent="center">
              <StyledHeader>
                <Typography fontSize={"24px"}>Your Products a</Typography>
                <Typography>Please note all prices are exclude GST</Typography>
              </StyledHeader>
              <Box>
                <Table>
                  <TableHead>
                    <TableRow style={{ height: "56px" }}>
                      <StyledTableCell align="right">
                        Style Name
                      </StyledTableCell>
                      <StyledTableCell>Description</StyledTableCell>
                      <StyledTableCell align="right">
                        Unit Price
                      </StyledTableCell>
                      {hasDiscount && (
                        <>
                          <StyledTableCell align="right">
                            Discount
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Discounted Price
                          </StyledTableCell>
                        </>
                      )}
                      <StyledTableCell align="right">Quantity</StyledTableCell>
                      <StyledTableCell align="right">
                        Extra Charges
                      </StyledTableCell>

                      <StyledTableCell align="right">Price</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="right">{item?.style}</TableCell>

                        <TableCell>
                          {item?.name
                            ? item?.name
                            : `${item?.price?.item.name} - ${item?.price?.fabric.name}`}
                        </TableCell>
                        <TableCell align="right">
                          {convertToPlaces(item?.original_unit_price || 0)}
                        </TableCell>
                        {hasDiscount && (
                          <>
                            <TableCell align="right">
                              {item?.unit_discount
                                ? convertToPlaces(item?.unit_discount)
                                : ""}
                            </TableCell>
                            <TableCell align="right">
                              {convertToPlaces(item?.unit_price)}
                            </TableCell>
                          </>
                        )}

                        <TableCell align="right">{item?.qty}</TableCell>
                        <TableCell align="right">
                          {item?.extra_charges
                            ? convertToPlaces(item?.extra_charges)
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          {convertToPlaces(
                            item?.original_total_price -
                              (item?.unit_discount * item?.qty || 0)
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Stack>
          </Card>
          <Box>
            {file.length !== 0 && (
              <>
                <Typography
                  sx={{
                    fontWeight: 600,
                    marginTop: "30px",
                    marginBottom: "20px",
                  }}
                >
                  Concept Design
                </Typography>
                <StyledImageContainer>
                  {file?.map((i, index) => (
                    <Box
                      key={i?.id}
                      sx={{
                        display: "flex",
                        padding: "5px",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <InsertDriveFileIcon
                          color="primary"
                          sx={{ marginRight: "5px" }}
                        />
                        <Typography variant="span">{i?.name}</Typography>
                      </Box>
                      <Button onClick={() => downloadUsingAnchorElement(index)}>
                        Download File
                      </Button>
                    </Box>
                  ))}
                </StyledImageContainer>
              </>
            )}
          </Box>
        </div>
        <div>
          <Stack direction={"column"} style={{ minWidth: "375px" }} spacing={3}>
            <StyledCard>
              <Stack textAlign={"left"} spacing={3}>
                <Typography fontSize={"20px"}>Your Quote</Typography>
                <Stack spacing={1}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <StyledTypography>Order total (ex GST)</StyledTypography>
                    <StyledTypography>
                      {convertToPlaces(originalTotal)}
                    </StyledTypography>
                  </Stack>

                  {totalDiscount > 0 && (
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <StyledTypography>Discounts applied</StyledTypography>
                      <StyledTypography>
                        {convertToPlaces(totalDiscount)}
                      </StyledTypography>
                    </Stack>
                  )}

                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <StyledTypography>Delivery</StyledTypography>
                    <StyledTypography>
                      {convertToPlaces(totalDelivery)}
                    </StyledTypography>
                  </Stack>
                  {!!artWorkCharges && (
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <StyledTypography>Artwork charges</StyledTypography>
                      <StyledTypography>
                        {convertToPlaces(artWorkCharges)}
                      </StyledTypography>
                    </Stack>
                  )}
                  {extraCharges > 0 && (
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <StyledTypography>Add-ons</StyledTypography>
                      <StyledTypography>
                        {convertToPlaces(extraCharges)}
                      </StyledTypography>
                    </Stack>
                  )}
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    textAlign={"right"}
                  >
                    <StyledTypography>Total Excluding Tax</StyledTypography>
                    <Stack justifyContent={"flex-end"}>
                      <StyledTypography>
                        {convertToPlaces(totalPrice)}
                      </StyledTypography>
                    </Stack>
                  </Stack>
                  <Divider style={{ backgroundColor: "#E0E0E0" }} />
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <StyledTypography>GST</StyledTypography>
                    <StyledTypography>{convertToPlaces(gst)}</StyledTypography>
                  </Stack>
                  <Divider style={{ backgroundColor: "#E0E0E0" }} />
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <StyledTypography>Total</StyledTypography>
                    <StyledTypography>
                      {/* {convertToPlaces(
                        extraCharges + grandTotal + totalDelivery + gst
                      )} */}
                      {convertToPlaces(grandTotal)}
                    </StyledTypography>
                  </Stack>
                </Stack>

                {/* {roles.length !== 0 &&
                  roles.map((role, index) =>
                    role.name === "Super Admin" ? (
                      <Stack key={index} direction={"row"} spacing={2}>
                        <ErrorOutlineIcon />
                        <StyledTypography paragraph fontSize={"13px"}>
                          <StyledItalic>
                            If you’d like to proceed with this order, simply
                            ‘Accept’ and we’ll get started! You can add
                            additional request by clicking
                            <StyledLinked onClick={() => setAddNote(true)}>
                              hear
                            </StyledLinked>
                          </StyledItalic>
                        </StyledTypography>
                      </Stack>
                    ) : (
                      ""
                    )
                  )} */}
              </Stack>
            </StyledCard>

            {quotationStatus != "rejected" && quotationStatus != "accepted" ? (
              <>
                <StyledButton
                  color="success"
                  variant="contained"
                  onClick={() => acceptAction()}
                  disabled={!agree}
                >
                  Accept Quotation
                </StyledButton>
                <Stack textAlign={"left"} spacing={1}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox onClick={(e) => checkBoxHandle(e)} />}
                      label="By checking this box, you are agreeing to our terms and conditions"
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox onClick={(`e) => checkBoxHandle(e)} id="create" />
                      }
                      label="create a new customer when accepting the quotation"
                    />
                  </FormGroup> */}
                </Stack>
                <Divider>
                  <Typography b>OR</Typography>
                </Divider>
                <Button
                  color="error"
                  variant="outlined"
                  style={{ height: "45px" }}
                  onClick={() => {
                    if (quotationStatus === "rejected") {
                      setToastMessage(
                        `The Quotation number #${id} is already been Rejected`
                      );
                      setToastState({
                        open: true,
                        vertical: "top",
                        horizontal: "right",
                      });
                    } else {
                      updateAdditional(true);
                    }
                  }}
                >
                  Request Change
                </Button>
              </>
            ) : null}
          </Stack>
        </div>
        <AdditionalCustomerQuotation
          open={openAdditional}
          handleClose={updateAdditional}
          request={request}
          error={error}
        />
        <SuccesModal
          {...success}
          handleClose={() => {
            setSuccess({});
            navigate("/quotations");
          }}
        />
        <AlertToast
          toastMessage={toastMessage}
          toastState={toastState}
          handleClose={handleToastClose}
        />
        <AdditionalCustomerQuotation
          open={addNote}
          handleClose={setAddNote}
          request={addMessage}
          type={ADD_REQUEST}
          error={error}
        />

        {/*{actionModels}*/}
      </Stack>
      <Box className={classes.section}>
        <Typography className={classes.termsAndConditionsHeader}>
          TERMS AND CONDITIONS
        </Typography>
        <Box>
          <Box className={classes.termsAndConditionRow}>
            <Typography>1.</Typography>
            <Typography>
              <strong>Delivery: </strong>
              Orders are delivered within 4-6 weeks from artwork approval, with
              the exception of some accessories. If you have specific deadlines,
              please let us know in advance as express options may be available.
            </Typography>
          </Box>
          <Box className={classes.termsAndConditionRow}>
            <Typography>2.</Typography>
            <Typography>
              <strong>Pricing: </strong> All prices exclude GST.
            </Typography>
          </Box>
          <Box className={classes.termsAndConditionRow}>
            <Typography>3.</Typography>
            <Typography>
              <strong>Credit Card Payments: </strong> A 2% surcharge applies.
            </Typography>
          </Box>
          <Box className={classes.termsAndConditionRow}>
            <Typography>4.</Typography>
            <Typography>
              <strong>Payment Terms: </strong> A 50% deposit is required to
              begin production, with the balance due before dispatch.
            </Typography>
          </Box>
          <Box className={classes.termsAndConditionRow}>
            <Typography>5.</Typography>
            <Typography>
              <strong>Artwork Fees: </strong> Artwork is included with 3
              revisions for orders of 16+ units per style. For orders under 16
              units, a $50.00 design fee per style applies, with 50% refunded
              for orders of 5+ units.
            </Typography>
          </Box>
          <Box className={classes.termsAndConditionRow}>
            <Typography>6.</Typography>
            <Typography>
              <strong>Logo Format: </strong> Non-vector logos (.ai, .pdf, .eps)
              require a $20.00 redraw fee.
            </Typography>
          </Box>
          <Box className={classes.termsAndConditionRow}>
            <Typography>7.</Typography>
            <Typography>
              <strong>Personalisation: </strong> For sublimated items,
              personalisation such as names and numbers incurs an additional
              cost of $1/unit, this is already incorporated into your quoted
              unit price. 
            </Typography>
          </Box>
          <Box className={classes.termsAndConditionRow}>
            <Typography>8.</Typography>
            <Typography>
              <strong>Pre-Production Samples: </strong> The logo application on
              garments will be tailored to each style, with the provided artwork
              serving as a reference only for size and placement. We will
              provide pre-production visual samples for your approval before
              moving forward with bulk production.
            </Typography>
          </Box>
        </Box>
        <ProgressCircularWithBackdrop open={openProgressCircular} />
      </Box>
    </div>
  );
};

const QuotationAcceptedInfoModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          Quotation Accepted
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          Your quotation has been accepted successfully.
        </Typography>
        <Button
          onClick={handleClose}
          sx={{ mt: 2 }}
          variant="contained"
          color="primary"
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};
