import React, { useState, useMemo } from "react";
import styled from "@emotion/styled";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  Box,
  Button,
  FormControl,
  InputAdornment,
} from "@mui/material";
import PropTypes from "prop-types";
import { convertToPlaces, convertToDecimalPlaces } from "../../helpers/helpers";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const StyledTd = styled("td")({
  fontSize: "14px",
  fontWeight: "400",
});

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  flexDirection: "column",
  height: "536px",
  borderRadius: "5px",
  color: theme.palette.neutral.light,
  padding: "5px 10px 0 15px",
}));

const StyledTotals = styled(Typography)({
  fontSize: "14px",
});

const StyledHeaders = styled(Typography)({
  fontSize: "16px",
});

const OrderSummery = ({ ...props }) => {
  const [enableRedraw, setRedraw] = useState(false);
  const [enableColor, setColor] = useState(false);
  const [newCost, setNewCost] = useState({
    redraw: 0,
    matchColor: 0,
  });

  const {
    grandTotal,
    items = [],
    custom_items = [],
    organization_name,
    file,
    totalDelivery,
    updateTotalDelivery,
    setUpdateTotalDelivery,
    handleGetDelivery,
    view,
  } = props;
  const [enableDelivery, setEnableDelivery] = useState(false);

  const totals = useMemo(() => {
    const allItems = [...items, ...custom_items];

    return allItems.reduce(
      (acc, item) => {
        acc.totalPrice += item?.total_price || 0;
        acc.totalShipping += item?.estimated_shipping_charges || 0;
        acc.totalGst += item?.gst_amount || 0;
        acc.totalExcludingTax += item?.sub_total || 0;
        acc.extraCharges += +item?.extra_charges || 0;
        acc.artWorkCharges += +item?.artwork_charges || 0;
        acc.grandTotal += item?.grand_total || 0;
        return acc;
      },
      {
        totalPrice: 0,
        totalExcludingTax: 0,
        totalShipping: 0,
        totalGst: 0,
        extraCharges: 0,
        artWorkCharges: 0,
        grandTotal: 0,
      }
    );
  }, [items, custom_items]);

  totals.grandTotal = grandTotal;

  const {
    totalPrice,
    totalExcludingTax,
    totalShipping,
    totalGst,
    extraCharges,
    artWorkCharges,
    grandTotal: calculatedGrandTotal,
  } = totals;

  const checkBoxHandle = (e) => {
    const { id, checked } = e.target;
    if (id === "enableRedraw") {
      setRedraw(checked);
    } else {
      setColor(checked);
    }
  };

  const onChange = (e) => {
    const { id, value } = e.target;
    setNewCost((state) => ({
      ...state,
      [id]: value,
    }));
  };

  const downloadUsingAnchorElement = async (index) => {
    const anchor = document.createElement("a");
    anchor.href = file[index].file_url;
    anchor.download = "*";
    document.body.appendChild(anchor);
    anchor.click();
  };

  const handleEnableDelivery = () => {
    setEnableDelivery(!enableDelivery);
    if (enableDelivery) {
      handleGetDelivery(items, "reset");
    }
  };

  const onchangeDelivery = (e) => {
    setUpdateTotalDelivery(e.target.value);
  };

  return (
    <>
      {file && file?.length !== 0 && (
        <Box>
          <Typography>Pattern File</Typography>
          {file?.map((i, index) => (
            <Box
              key={i?.id}
              sx={{
                display: "flex",
                padding: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <InsertDriveFileIcon
                  color="primary"
                  sx={{ marginRight: "5px" }}
                />
                <Typography variant="span">{i?.name}</Typography>
              </Box>
              <Button onClick={() => downloadUsingAnchorElement(index)}>
                Download File
              </Button>
            </Box>
          ))}
        </Box>
      )}

      <StyledStack spacing={2}>
        <Stack>
          <Typography fontSize={"24px"} fontWeight={"400"}>
            Sales Order Summary
          </Typography>
          <Typography color={"neutral.main"} fontSize={"14px"}>
            This is a summary of the generated sales order
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <Stack>
            <Typography>Organization Name:</Typography>
            <Typography>{organization_name}</Typography>
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <Typography color={"neutral.main"}>Include in Order:</Typography>
          <Divider style={{ backgroundColor: "#E0E0E0" }} />
          <Stack>
            {items && (
              <table>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={index}>
                      <StyledTd>
                        {item?.price?.item?.name || item?.name}
                      </StyledTd>
                      <StyledTd>* {item?.qty}</StyledTd>
                      <StyledTd style={{ textAlign: "right" }}>
                        {convertToPlaces(
                          item?.original_total_price -
                            (item?.unit_discount * item?.qty || 0)
                        )}
                        {item?.unit_discount ? (
                          <Stack
                            direction={"row"}
                            spacing={1}
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                          >
                            <StyledTd
                              variant="caption"
                              style={{
                                fontSize: "12px",
                                textDecoration: "line-through",
                              }}
                            >
                              {convertToPlaces(item?.original_total_price)}
                            </StyledTd>
                            <StyledTd
                              style={{
                                color: "yellow",
                                fontSize: "10px",
                              }}
                            >
                              {convertToPlaces(
                                item?.original_total_price - item?.total_price
                              )}
                              {" OFF"}
                            </StyledTd>
                          </Stack>
                        ) : null}
                      </StyledTd>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {custom_items && (
              <table>
                <tbody>
                  {custom_items.map((item, index) => (
                    <tr key={index}>
                      <StyledTd>{item?.name}</StyledTd>
                      <StyledTd>* {item?.qty}</StyledTd>
                      <StyledTd style={{ textAlign: "right" }}>
                        {convertToPlaces(item?.original_total_price)}
                        {item?.unit_discount ? (
                          <Stack
                            direction={"row"}
                            spacing={1}
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                          >
                            <StyledTd
                              variant="caption"
                              style={{
                                fontSize: "12px",
                                textDecoration: "line-through",
                              }}
                            >
                              {convertToPlaces(item?.original_total_price)}
                            </StyledTd>
                            <StyledTd
                              style={{
                                color: "yellow",
                                fontSize: "10px",
                              }}
                            >
                              {convertToPlaces(
                                item?.original_total_price - item?.total_price
                              )}
                              {" OFF"}
                            </StyledTd>
                          </Stack>
                        ) : null}
                      </StyledTd>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Stack>

          <Stack spacing={1}>
            {extraCharges ? (
              <Stack direction={"row"} justifyContent="space-between">
                <StyledTotals>Add-ons</StyledTotals>
                <StyledTotals>{convertToPlaces(extraCharges)}</StyledTotals>
              </Stack>
            ) : null}

            {artWorkCharges ? (
              <Stack direction={"row"} justifyContent="space-between">
                <StyledTotals>Artwork charges</StyledTotals>
                <StyledTotals>{convertToPlaces(artWorkCharges)}</StyledTotals>
              </Stack>
            ) : null}

            <Stack direction={"row"} justifyContent="space-between">
              <StyledTotals>Estimated Shipping</StyledTotals>
              <StyledTotals>
                {convertToDecimalPlaces(updateTotalDelivery)}
              </StyledTotals>
            </Stack>

            <Stack direction={"row"} justifyContent="space-between">
              <StyledTotals>Total Excluding Tax</StyledTotals>
              <StyledTotals>
                {convertToPlaces(totalExcludingTax) || 0.0}
              </StyledTotals>
            </Stack>

            {totalGst !== 0 && (
              <>
                <Divider style={{ backgroundColor: "#E0E0E0" }} />
                <Stack direction={"row"} justifyContent="space-between">
                  <StyledTotals>GST</StyledTotals>
                  <StyledTotals>{convertToPlaces(totalGst)}</StyledTotals>
                </Stack>
              </>
            )}
            <Divider style={{ backgroundColor: "#E0E0E0" }} />
            <Stack direction={"row"} justifyContent="space-between">
              <StyledTotals style={{ fontWeight: "900" }}>
                Grand Total
              </StyledTotals>
              <StyledTotals style={{ fontWeight: "900" }}>
                {convertToPlaces(calculatedGrandTotal)}
              </StyledTotals>
            </Stack>
            {!view && (
              <Stack spacing={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={enableDelivery}
                      checked={enableDelivery}
                      onChange={handleEnableDelivery}
                    />
                  }
                  label="Update Delivery Charges"
                />
                {enableDelivery && (
                  <>
                    <StyledHeaders>Delivery / Shipping</StyledHeaders>
                    <Stack spacing={2} direction={"row"}>
                      <FormControl variant="standard" fullWidth>
                        <TextField
                          sx={{
                            "& .MuiInputBase-root": {
                              color: "#fff", // Text color for the input
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#fff", // Border color
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#fff", // Border color on hover
                            },
                            "& .Mui-disabled": {
                              color: "#fff", // Text color when disabled
                              "-webkit-text-fill-color": "#fff", // To fix color in some browsers when disabled
                            },
                          }}
                          id="outlined-controlled"
                          size="small"
                          value={updateTotalDelivery}
                          onChange={onchangeDelivery}
                          disabled={!enableDelivery}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{ color: "#fff" }}
                              >
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Stack>
                  </>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </StyledStack>
    </>
  );
};

OrderSummery.propTypes = {
  items: PropTypes.array,
};
OrderSummery.defaultProps = {
  items: {
    items: [],
  },
};

export default OrderSummery;
