import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { useStyles } from "../../../Theme/Style";
import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useNotification } from "../../../Contexts/GlobalNotificationContext";
import {
  useGetFabricDetailsQuery,
  useSubmitFabricDetailsMutation,
} from "../../../services/api/production-order";

const FabricDetailsComponent = ({ prodId }) => {
  const classes = useStyles();

  const { addNotification } = useNotification();

  const { data: fabricData, isLoading } = useGetFabricDetailsQuery({
    id: prodId,
  });

  const [submitFabricDetails] = useSubmitFabricDetailsMutation();
  const [fabricsItems, setFabricItems] = useState([]);

  useEffect(() => {
    console.log(fabricData);

    if (fabricData?.fabrics) {
      const initialItems = fabricData.fabrics.map((f) => ({
        item: { id: f.item.id, name: f.item.name },
        fabricType: f.fabricType || {},
        color: f.color || {},
      }));
      setFabricItems(initialItems);
    }
  }, [fabricData]);

  const addNewRow = () => {
    setFabricItems([
      ...fabricsItems,
      { item: null, fabricType: null, color: null, position: null },
    ]);
  };

  const handleChange = (event, name, index, value) => {
    const updatedItems = [...fabricsItems];
    updatedItems[index][name] = value;
    setFabricItems(updatedItems);
  };

  const deleteRows = (index) => {
    const updatedItems = [...fabricsItems];
    updatedItems.splice(index, 1);
    setFabricItems(updatedItems);
  };

  const handleFabricSubmit = async () => {
    try {
      if (
        fabricsItems.every(
          (item) => item.item.id && item.fabricType.name && item.color.id
        )
      ) {
        await submitFabricDetails({ prodId, fabrics: fabricsItems }).unwrap();
        addNotification({
          message: "Fabric details submitted successfully!",
          type: "success",
        });
      } else {
        throw new Error("Please fill out all fields.");
      }
    } catch (e) {
      addNotification({
        message: e.message || "Failed to submit fabric details.",
        type: "error",
      });
    }
  };

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container className={classes.accordionSummaryTextContainer}>
          <Grid item xs={4}>
            Fabric Details
          </Grid>
          <Grid item xs={4}>
            面料细节
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.tableHeader}>
          <Grid container>
            <Grid item xs={3}>
              <Typography>Item</Typography>
              <Typography>物品</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Fabric Type</Typography>
              <Typography>面料类型</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Color</Typography>
              <Typography>颜色</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Position</Typography>
              <Typography>位置</Typography>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Box>
        {fabricsItems.map((item, index) => (
          <Box key={index} className={classes.row}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={fabricData?.items || []}
                    getOptionLabel={(option) =>
                      `${option.name} - ${option.chinese_name || ""}`
                    }
                    value={item.item}
                    onChange={(event, value) =>
                      handleChange(event, "item", index, value)
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Item" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={fabricData?.fabricTypes || []}
                    getOptionLabel={(option) =>
                      `${option.name} - ${option.chinese_name || ""}`
                    }
                    value={item.fabricType}
                    onChange={(event, value) =>
                      handleChange(event, "fabricType", index, value)
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Fabric Type" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={fabricData?.colors || []}
                    getOptionLabel={(option) =>
                      `${option.english_name} - ${option.chinese_name || ""}`
                    }
                    value={item.color}
                    onChange={(event, value) =>
                      handleChange(event, "color", index, value)
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Color" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={fabricData?.positions || []}
                    getOptionLabel={(option) =>
                      `${option.name} - ${option.chinese_name || ""}`
                    }
                    value={item.position}
                    onChange={(event, value) =>
                      handleChange(event, "position", index, value)
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Position" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={() => deleteRows(index)}
                  disabled={index === 0}
                  startIcon={<RemoveCircleOutline />}
                  color="error"
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          </Box>
        ))}

        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            onClick={addNewRow}
            startIcon={<AddCircleOutline />}
          >
            Add New Row
          </Button>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" onClick={handleFabricSubmit}>
            Save
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default FabricDetailsComponent;
