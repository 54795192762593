import { Box, Button, Stack, styled, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import InfoModal from "../Modals/InfoModal";
import EditSalesOrderModal from "../Modals/EditSalesOrderModal";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import WarningModal from "../Modals/WarningModal";
import { convertToPlaces } from "../../helpers/helpers";
import SizeAndNamesComponent from "../../components/SalesOrder/SizeAndNameAccordion";

const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "15px",
  justifyContent: "space-between",
  gap: "10px",
  display: "flex",
}));

const StyledTd = styled(TableCell)({
  fontSize: "14px",
  fontWeight: "700",
});

const StyledTdContent = styled(TableCell)({
  fontSize: "14px",
  fontWeight: "400",
});

const StyledCurrency = styled(Typography)({
  fontSize: "14px",
});

const StyledIconContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const OrderTable = ({
  item,
  updateItems,
  index,
  colors,
  view,
  type = "item",
}) => {
  const [openInfo, setInfo] = useState(false);
  const [openEdit, setEdit] = useState(false);
  const [openDelete, setDelete] = useState(false);
  const [status, setStatus] = useState(false);
  const deleteText = `This ${
    item?.price?.item?.name || item?.name
  } will be removed`;

  const editAction = (status) => {
    setEdit(true);
    setStatus(status);
  };

  const deleteAction = () => {
    setDelete(!openDelete);
  };

  return (
    <Stack spacing={1}>
      <Stack direction={"row"} justifyContent="space-between">
        <Typography>{item?.price?.item?.style || item?.style}</Typography>
        <StyledIconContainer>
          <Button
            variant="outlined"
            onClick={() => editAction("view")}
            sx={{ marginRight: "10px" }}
          >
            View
          </Button>
          {!view && (
            <>
              <Button variant="outlined" onClick={() => editAction("edit")}>
                <ModeEditOutlineOutlinedIcon fontSize="20px" /> Edit details
              </Button>

              <Button onClick={() => deleteAction()} sx={{ minWidth: "20px" }}>
                <DeleteForeverIcon width="10px" color="warning" />
              </Button>
            </>
          )}
        </StyledIconContainer>
      </Stack>
      <Table>
        <thead>
          <TableRow
            style={{
              backgroundColor: "#D5F9FC",
              height: "48px",
              textAlign: "center",
            }}
          >
            {/* <StyledTd>Pattern</StyledTd> */}

            <StyledTd>{item?.style ? "Name" : "Style Name"}</StyledTd>
            <StyledTd>Color</StyledTd>
            <StyledTd>Fabric</StyledTd>
            <StyledTd>Fabric Weight</StyledTd>
            <StyledTd>Quantity</StyledTd>
            <StyledTd>Notes</StyledTd>
          </TableRow>
        </thead>
        <tbody style={{ margin: "10px" }}>
          <TableRow style={{ padding: "10px", textAlign: "center" }}>
            {/* <StyledTdContent>{item?.pattern}</StyledTdContent> */}

            <StyledTdContent>
              {item?.style ? item?.style : item?.name}
            </StyledTdContent>
            <StyledTdContent>{item?.color}</StyledTdContent>
            <StyledTdContent>
              {item?.price?.fabric?.name || item?.fabric_name}
            </StyledTdContent>
            <StyledTdContent>
              {item?.price?.item?.weight?.name || "Light Weight"}
            </StyledTdContent>
            <StyledTdContent>{item?.qty}</StyledTdContent>
            <StyledTdContent>{item?.note}</StyledTdContent>
          </TableRow>
          <TableRow>
            <StyledTdContent colSpan={6} sx={{ padding: "10px 0 !important" }}>
              <SizeAndNamesComponent item={item} />
            </StyledTdContent>
          </TableRow>
        </tbody>
      </Table>

      <Stack direction={"row"} justifyContent="flex-end" spacing={1}>
        <StyledCurrency>
          Total:{" "}
          {convertToPlaces(
            item?.original_total_price - item?.qty * item?.unit_discount
          )}
          <Stack direction={"column"} spacing={2}>
            {item?.unit_discount ? (
              <Stack
                direction={"row"}
                spacing={1}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{
                    textDecoration: "line-through",
                  }}
                >
                  {convertToPlaces(item?.original_total_price)}
                </Typography>
                <Typography
                  color="textSecondary"
                  fontSize={"10px"}
                  style={{
                    color: "green",
                  }}
                >
                  {convertToPlaces(item?.qty * item?.unit_discount)}
                  {" OFF"}
                </Typography>
              </Stack>
            ) : null}
          </Stack>
        </StyledCurrency>
        <InfoModal open={openInfo} handleClose={setInfo} item={item} />
      </Stack>
      <EditSalesOrderModal
        open={openEdit}
        handleClose={setEdit}
        item={item}
        updateItems={updateItems}
        index={index}
        status={status}
        colors={colors}
      />
      <WarningModal
        open={openDelete}
        handleClose={setDelete}
        title="Warning"
        body={deleteText}
        updateItems={updateItems}
        index={index}
      />
    </Stack>
  );
};

OrderTable.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
};
OrderTable.defaultProps = {
  item: {},
  title: "",
};

export default OrderTable;
