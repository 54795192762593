import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MultiSelect } from "react-multi-select-component"; // Install this library
import { authHeader } from "../../helpers";
import { apiConstants } from "../../constants";
import dayjs from "dayjs";

const columns = [
  {
    field: "created_at",
    headerName: "Date",
    width: 125,
    renderCell: (params) => dayjs(params.row.created_at).format("DD/MM/YYYY"),
  },
  {
    field: "organization_name",
    headerName: "Organization Name",
    width: 200,
    valueGetter: (params) => `${params.row.organization_name || ""}`,
  },
  {
    field: "invoice_no",
    headerName: "Invoice No",
    width: 200,
    valueGetter: (params) => `${params.row.invoice_no || ""}`,
  },
  {
    field: "total_items",
    headerName: "No of Items",
    width: 200,
    valueGetter: (params) => `${params.row.total_items || "0"}`,
  },
];

const OrdersQuantitiesReport = () => {
  const [rows, setRows] = React.useState([]);
  const [response, setResponse] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [filterByDates, setFilterByDates] = React.useState({
    start_date: "",
    end_date: "",
  });
  const [selectedCustomers, setSelectedCustomers] = React.useState([]);
  const [customersOptions, setCustomersOptions] = React.useState([]);

  React.useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch(
          `${apiConstants.API_URL}/organizations/all/list`,
          {
            method: "GET",
            headers: authHeader(),
          }
        );
        const data = await response.json();

        setCustomersOptions(
          data?.map((customer) => ({
            label: customer.name ? customer.name : customer.email,
            value: customer.id,
          }))
        );
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };
    fetchCustomers();
  }, []);

  const handleGetReport = async () => {
    setLoading(true);

    const customerIds = selectedCustomers
      .map((customer) => customer.value)
      .join(",");
    const queryParams = new URLSearchParams({
      start_date: filterByDates.start_date,
      end_date: filterByDates.end_date,
      organization_ids: customerIds,
    });

    try {
      const response = await fetch(
        `${apiConstants.API_URL}/all-orders-quantities-report?${queryParams}`,
        { method: "GET", headers: authHeader() }
      );
      const data = await response.json();
      setRows(data?.data || []);
      setResponse(data?.meta || {});
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          margin: "14px 0px 16px",
          backgroundColor: "#EFEFEF",
          padding: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            flexWrap: "wrap",
          }}
        >
          {/* Start Date Picker */}
          <DatePicker
            label="Start Date"
            id="start-date"
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
            value={dayjs(filterByDates.start_date)}
            onChange={(newValue) => {
              setFilterByDates({
                ...filterByDates,
                start_date: newValue
                  ? dayjs(newValue).format("YYYY-MM-DD")
                  : "",
              });
            }}
          />

          {/* End Date Picker */}
          <DatePicker
            label="End Date"
            id="end-date"
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
            value={dayjs(filterByDates.end_date)}
            onChange={(newValue) => {
              setFilterByDates({
                ...filterByDates,
                end_date: newValue ? dayjs(newValue).format("YYYY-MM-DD") : "",
              });
            }}
          />

          {/* Customer MultiSelect */}
          <MultiSelect
            options={customersOptions}
            value={selectedCustomers}
            onChange={setSelectedCustomers}
            labelledBy="Select Customers"
            sx={{ width: "100%" }}
            className="multi-select-container"
          />

          {/* Get Report Button */}
          <Button
            variant="contained"
            sx={{ height: "42px" }}
            onClick={handleGetReport}
          >
            Get Report
          </Button>
        </Box>
      </Box>

      <Box style={{ width: "100%" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
          <DataGrid
            autoHeight
            rows={rows}
            pageSize={response?.per_page || 10}
            pagination
            getRowId={(row) => row.id}
            rowCount={response?.total || 0}
            columns={columns}
            paginationMode="server"
          />
        )}
      </Box>
    </>
  );
};

export default OrdersQuantitiesReport;
