import React from "react";
import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import Dividers from "../../Common/Dividers";
import PropTypes from "prop-types";
import { convertToPlaces } from "../../helpers/helpers";

const StyledSalesOrder = styled(Stack)(({ theme }) => ({
  width: "100%",
  minWidth: "250px",
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.neutral.light,
  textAlign: "left",
  borderRadius: "4px",
  padding: "10px",
}));

const StyledTd = styled("td")({
  fontSize: "14px",
  fontWeight: "400",
});

const StyledText = styled(Typography)({
  fontSize: "14px",
});

const StyledDivider = styled(Dividers)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.neutral.main}`,
}));

const StyledTotalText = styled(Typography)({
  fontSize: "14px",
  fontWeight: 700,
});

const SalesSummeryCustomer = ({
  data,
  displayData,
  activeStep,
  totalDelivery,
}) => {
  let totalGst = 0;
  let totalShipping = totalDelivery;
  let excludingTotal = 0;
  let extraCharges = 0;

  console.log("sales order data", data);

  const allItems = [...(data?.items || []), ...(data?.custom_items || [])];

  console.log("items", allItems);

  allItems.forEach((item, index) => {
    // if (index <= activeStep) {
    totalGst += item?.gst_amount || 0; // Add GST amount safely
    // totalShipping += item?.estimated_shipping_charges || 0;  // Uncomment if needed
    excludingTotal += item?.total_price || 0; // Add total price safely
    extraCharges += item?.extra_charges || 0; // Add extra charges safely
    // }
  });

  let total = totalGst + totalShipping + excludingTotal + extraCharges;

  return (
    <StyledSalesOrder spacing={1}>
      <Typography fontSize={"24px"}>Sales Order Summary</Typography>
      <Stack spacing={2}>
        <Stack>
          {allItems?.map((item, index) => (
            <Stack direction={"row"} justifyContent="space-between" key={index}>
              <StyledText>
                {item?.price?.item?.name ? item?.price?.item?.name : item.name}
              </StyledText>
              <StyledText>
                <StyledTd style={{ textAlign: "right" }}>
                  {convertToPlaces(
                    item?.original_total_price -
                      (item?.unit_discount * item?.qty || 0)
                  )}
                  {item?.unit_discount ? (
                    <Stack
                      direction={"row"}
                      spacing={1}
                      alignItems={"center"}
                      justifyContent={"flex-end"}
                    >
                      <StyledTd
                        variant="caption"
                        style={{
                          fontSize: "12px",
                          textDecoration: "line-through",
                        }}
                      >
                        {convertToPlaces(item?.original_total_price)}
                      </StyledTd>
                      <StyledTd
                        style={{
                          color: "yellow",
                          fontSize: "10px",
                        }}
                      >
                        {convertToPlaces(
                          item?.original_total_price - item?.total_price
                        )}
                        {" OFF"}
                      </StyledTd>
                    </Stack>
                  ) : null}
                </StyledTd>
              </StyledText>
            </Stack>
          ))}
        </Stack>

        {/* Totals */}
        <Stack spacing={1}>
          {extraCharges ? (
            <Stack direction={"row"} justifyContent="space-between">
              <StyledText>Add-ons</StyledText>
              <StyledText>{convertToPlaces(extraCharges)}</StyledText>
            </Stack>
          ) : null}
          <Stack direction={"row"} justifyContent="space-between">
            <StyledText>Total Excluding Tax</StyledText>
            <StyledText>{convertToPlaces(excludingTotal)}</StyledText>
          </Stack>
          <Stack direction={"row"} justifyContent="space-between">
            <StyledText>Estimated Shipping</StyledText>
            <StyledText> {convertToPlaces(totalShipping)}</StyledText>
          </Stack>
          {totalGst !== 0 && (
            <>
              <StyledDivider />
              <Stack direction={"row"} justifyContent="space-between">
                <StyledText>GST</StyledText>
                <StyledText>{convertToPlaces(totalGst)}</StyledText>
              </Stack>
            </>
          )}
          <StyledDivider />
          <Stack direction={"row"} justifyContent="space-between">
            <StyledTotalText>Grand Total</StyledTotalText>
            <StyledTotalText>{convertToPlaces(total)}</StyledTotalText>
          </Stack>
        </Stack>
      </Stack>
    </StyledSalesOrder>
  );
};

SalesSummeryCustomer.propTypes = {
  data: PropTypes.object,
};
SalesSummeryCustomer.defaultProps = {
  data: {
    items: [],
  },
};
export default SalesSummeryCustomer;
