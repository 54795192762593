import moment from "moment";
import * as React from "react";

import { checkIsAdmin, convertToPlaces } from "../../../helpers/helpers";
import { QuotationsActionButton } from "../../atoms/ActionButton/QuotationsActionButton";
import CustomGrid from "../../atoms/CustomGrid/CustomGrid";
import QuotationStatusLable from "../../atoms/StatusLable/QuotationStatusLable";
import { Stack, Typography } from "@mui/material";
import { render } from "react-dom";

function isViewOnly(row) {
  if (!checkIsAdmin()) {
    return true;
  }

  if (
    row &&
    row.quotation_status &&
    row.quotation_status.keyword &&
    row.quotation_status.keyword === "submitted"
  ) {
    return true;
  }

  return false;
}

const calculateGrandTotal = (record) => {
  return (
    record?.items?.reduce((pre, curr) => {
      const qty = curr.qty;
      const unitPrice = curr.unit_price;
      const unitDiscount = curr.unit_discount;
      const gst = curr.gst_amount;
      const artworkCharges = curr.artwork_charges;
      const extraCharges = curr.extra_charges;

      return (
        pre +
        qty * (unitPrice - unitDiscount) +
        gst +
        artworkCharges +
        extraCharges
      );
    }, 0) + record?.estimated_shipping_charges || 0
  );
};

export const QuotationsTable = ({
  quotations,
  perPageCount,
  totalRowsConunt,
  handlePageChange,
  pathToView,
}) => {
  const columns = [
    { field: "id", headerName: "Quotation ID", resizable: false },
    {
      field: "created_at",
      headerName: "Date",
      // width: 150,
      renderCell: (params) =>
        moment(params.row.created_at).format("DD/MM/YYYY"),
    },
    {
      field: "org_id",
      headerName: "Organization ID",
      sortable: true,
      minWidth: 100,
      valueGetter: (params) => `${params.row?.organization?.id || ""}`,
    },
    {
      field: "organization_name",
      headerName: "Organization Name",
      sortable: true,
      width: 200,
      // minWidth: 200,
      valueGetter: (params) => `${params.row.organization_name || ""}`,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => <QuotationStatusLable {...params} />,
      width: 150,
    },
    {
      field: "grand_total",
      headerName: "Amount",
      renderCell: (params) =>
        `${convertToPlaces(params.row.grand_total) || "$ 0.00"}`,
    },
    {
      field: "accepted_by",
      headerName: "Submitted/Accepted By",
      minWidth: 200,
      renderCell: (params) =>
        params.row?.accepted?.length !== 0 ? (
          <Stack direction="column">
            <div
              style={{ fontSize: "12px", color: "#666", marginRight: "10px" }}
            >
              Accepted By
            </div>
            <span>
              {params.row?.accepted?.[0]?.user?.first_name}{" "}
              {params.row?.accepted?.[0]?.user?.last_name}
            </span>
          </Stack>
        ) : (
          <Stack direction="column">
            <span
              style={{ fontSize: "12px", color: "#666", marginRight: "10px" }}
            >
              Submitted By
            </span>
            <span>
              {params.row?.sales_agent?.first_name}{" "}
              {params.row?.sales_agent?.last_name}
            </span>
          </Stack>
        ),
    },
    {
      field: "accepted_date",
      headerName: "Submitted/Accepted Date",
      minWidth: 200,
      renderCell: (params) =>
        params.row?.accepted?.length !== 0 ? (
          <Stack direction="column">
            <span>
              {moment(params.row?.accepted?.[0]?.created_at).format(
                "DD, MMM YYYY"
              )}
            </span>
          </Stack>
        ) : (
          <Stack direction="column">
            <span>{moment(params.row?.created_at).format("DD, MMM YYYY")}</span>
          </Stack>
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      type: "actions",
      renderCell: (params) => (
        <QuotationsActionButton
          isViewOnly={isViewOnly(params.row)}
          pathToView={pathToView}
          quoteId={params.row.id}
          status={
            params.row.quotation_status
              ? params.row.quotation_status.keyword
              : params.row.status.keyword
          }
        />
      ),
    },
  ];

  return (
    <CustomGrid
      rows={quotations}
      columns={columns}
      perPageCount={perPageCount}
      totalRowCount={totalRowsConunt}
      handlePageChange={handlePageChange}
    />
  );
};
